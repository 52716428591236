/*
 * @Descripttion: 综合分析模块接口
 * @Author: Dyf
 * @Date: 2023-04-12
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-14
 */
import HTTP from "@utils/http";

import { PAGE_SIZE } from "@config";

/*******
 * @Descripttion:
 * @Author: Dyf
 * @param {*} prams
 * @return {*}
 */
export async function $analysisData(prams) {
  try {
    return await HTTP.post("/teacher/stuuser/class_study_analysis", prams);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取班级学生列表
 * @Author: Dyf
 * @param {number} sccla_id 班级id
 * @return {*}
 */
export async function $students(sccla_id) {
  try {
    return await HTTP.post("/teacher/stuuser/wholelist", { sccla_id });
  } catch (error) {
    return error;
  }
}

/******* 
 * @Descripttion: 错题列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $wrongQues(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post("/teacher/stuquestionscschid/list", {
      ...params,
      pagesize,
    });
  } catch (error) {
    return error;
  }
}
