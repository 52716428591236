<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 13:50:05
 * @LastEditTime: 2023-04-10 17:58:24
 * @Descripttion: 【综合分析】错题数
-->
<style lang="scss" scoped>
.wrong-questions {
    @include innerPage;
    @include pageHead(-10px);
    @include pageFoot;

    .page-head {
        height: 240px;
        flex-wrap: wrap;
        padding-top: 0;

        .head-wrapper.column {
            margin-top: 12px;
        }

        .page-tab {
            &--wrapper {
                width: 100%;
                height: 76px;
                @include flexBox;

                .student {
                    height: 66px;
                    @include flexBox;
                    margin-right: 190px;
                    margin-left: 22px;

                    .name {
                        margin: 0 32px 0 16px;
                        font-size: 16px;
                        color: #343434;
                    }

                    .all-circle {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        background: #6c4ecb;
                        color: #fff;
                        line-height: 66px;
                        text-align: center;
                        margin-right: 16px;
                    }
                }
            }

            width: auto;
            justify-content: flex-start;
            margin: 0 auto;
            box-sizing: border-box;
            padding-right: 200px;

            .tabs {
                border-bottom: none;
                justify-content: flex-start;
            }

            .more-subject {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #6c4ecb;
                margin-right: auto;
                margin-left: 60px;
                margin-top: 6px;
                cursor: pointer;

                &:hover {
                    background: #8971d5;
                }

                .iconfont {
                    font-size: 18px;
                    text-align: center;
                    line-height: 24px;
                    color: #fff;
                }
            }
        }
    }

    .page-inner {
        width: 1408px;
        height: calc(100% - 140px);
        margin: 0 auto;

        .search-form {
            width: calc(100% - 158px);
            height: 46px;
            margin: 30px auto 20px;
            @include flexBox;

            ::v-deep .el-form {
                flex-grow: 1;

                &-item {
                    &:first-child {
                        flex-grow: 1;
                    }

                    &:nth-child(n + 2) {
                        width: 252px;
                    }

                    .el-input__inner {
                        background: #f1f2f8;
                    }
                }
            }
        }

        .total-num {
            height: 40px;
            text-align: center;
            line-height: 40px;
            margin-right: 30px;
            color: #333;

            .pf_bold {
                font-size: 26px;
            }
        }
    }

    &-wrapper {
        width: 100%;
        height: calc(100% - 204px);
        margin: 0 auto;

        .custom-foot {
            border-top: none;
        }
    }

    &-box {
        width: calc(100% + 20px);
        height: calc(100% - 74px);
        position: relative;

        &.no-data {
            width: 100%;
            background: #fff;
            border-radius: 10px;
        }
    }

    &-list {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-right: 20px;
        overflow: hidden;
        overflow-y: auto;

        .question {
            width: 100%;
            background: #eef0f8;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 30px 48px 0;
            margin-top: 36px;
            @include flexBox;
            align-items: flex-start;
            flex-wrap: wrap;

            &:first-child {
                margin-top: 0;
            }

            &--num,
            &--type {
                flex-shrink: 0;
                line-height: 24px;
                flex-shrink: 0;
            }

            &--num {
                color: #1f1f1f;
                font-size: 16px;
            }
            &--icon{
                height: 24px;
                margin-right: 10px;
                ::v-deep .el-button--mini{
                    height: 24px;
                    padding: 0 8px;
                }
            }
            &--type {
                min-width: 56px;
                height: 24px;
                background: #6340c8;
                border-radius: 12px 0px 12px 12px;
                text-align: center;
                color: #fff;
                margin: 0 14px 0 4px;
                box-sizing: border-box;
                padding: 0 4px;
            }


            &--data {
                flex-grow: 1;
                max-width: calc(100% - 120px);

                h5 {
                    line-height: 24px;
                    color: #1f1f1f;
                }

                .imgs-group {
                    margin-top: 10px;
                    @include flexBox;

                    .el-image {
                        width: 130px;
                        height: 78px;
                        border-radius: 6px;
                        margin-right: 10px;
                    }
                }

                .options {
                    margin-top: 14px;

                    li {
                        line-height: 30px;
                        color: #666666;

                        .imgs-group {
                            margin-top: 0;
                            margin-bottom: 16px;
                            box-sizing: border-box;
                            padding-left: 18px;
                        }
                    }
                }
            }

            &-condition {
                width: 100%;
                height: 72px;
                box-sizing: border-box;
                border-top: 1px solid #dee0e7;
                color: #666;
                margin-top: 20px;
                @include flexBox;

                p {
                    width: 120px;

                    &:nth-child(2) {
                        text-align: right;
                        position: relative;

                        &::before {
                            content: '';
                            height: 16px;
                            border-left: 1px solid #d6d7de;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                .danger,
                .success {
                    font-size: 20px;
                }

                .danger {
                    color: #f83d3d;
                }

                .success {
                    color: #2ac293;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .compose {
            padding-bottom: 40px;

            h5 {
                @include flexBox;
                align-items: baseline;

                span.bold {
                    display: inline-block;
                    flex-grow: 1;
                    margin-right: 20px;
                }

                .collect {
                    margin-right: 34px;
                }

                .iconfont {
                    margin-top: 3px;
                }

                .el-button {
                    margin-top: -7px;

                    &.is-circle {
                        margin-top: -4px;
                    }
                }
            }

            .subtopic {
                margin-top: 20px;

                &-item {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 40px 30px 0;
                    background: #e8eaf3;
                    border-radius: 10px;
                    color: #666;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &--title {
                    font-size: 16px;
                    line-height: 24px;
                }

                &--options {
                    box-sizing: border-box;
                    padding: 0 40px;
                    margin-top: 16px;

                    .option {
                        line-height: 30px;

                        .imgs-group {
                            margin-top: 0;
                            margin-bottom: 16px;
                            box-sizing: border-box;
                            padding-left: 18px;
                        }
                    }
                }
            }
        }
    }
}

.office{
    margin:0 auto;
    width: 800px;
    min-height: 400px;
    height: calc(100vh - 200px);
    overflow-y: auto;
}
</style>

<template>
    <section class="wrong-questions">
        <div class="page-head">
            <div class="page-head--inner">
                <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
                <div class="head-wrapper">
                    <breadcrumb />
                </div>
            </div>
            <div class="page-tab--wrapper">
                <!-- 学生 -->
                <div class="student">
                    <template v-if="!$isEmpty(currentStudent)">
                        <el-avatar :size="66" :src="formatfile(currentStudent.stuser_image)">
                            <img src="@assets/images/empty_avatar.png" />
                        </el-avatar>
                        <span class="name">{{ currentStudent.stuser_name }}</span>
                    </template>
                    <p class="all-circle" v-else>全部</p>
                    <el-dropdown class="more-student" placement="bottom-start" trigger="click"
                        v-if="teachStudents.length > 0">
                        <el-button type="custom_primary" size="medium">
                            切换<i class="iconfont">&#xe649;</i>
                        </el-button>
                        <el-dropdown-menu class="dropdown-menu-student" slot="dropdown">
                            <el-dropdown-item class="dropdown--all" :class="{ current: $isEmpty(currentStudent) }"
                                @click.native="chooseStudent(null)">
                                <p class="student-item">
                                    <span class="all">全部</span>
                                </p>
                            </el-dropdown-item>
                            <el-dropdown-item v-for="item in teachStudents" :key="item.stuser_id"
                                :class="{ current: !$isEmpty(currentStudent) && item.stuser_id == currentStudent.stuser_id }"
                                @click.native="chooseStudent(item)">
                                <p class="student-item">
                                    <el-avatar :size="62" :src="formatfile(item.stuser_image)">
                                        <img src="@assets/images/empty_avatar.png" />
                                    </el-avatar>
                                    <span>{{ item.stuser_name }}</span>
                                </p>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- 科目 -->
                <div class="page-tab">
                    <ul class="tabs bold">
                        <li class="tab" :class="{ current: !searchForm.sysub_id }" @click="switchSubject(null)">全部</li>
                        <li class="tab" :class="{ current: searchForm.sysub_id == item.sysub_id }"
                            v-for="(item, index) in teachSubject.slice(0, 4)" :key="item.sysub_id"
                            @click="switchSubject(index)">
                            {{ item.sysub_name }}
                        </li>
                    </ul>
                    <el-dropdown class="more-subject" placement="bottom-start" v-if="teachSubject.length > 4">
                        <p class="iconfont">&#xe8aa;</p>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item, index) in teachSubject.slice(4, teachSubject.length)"
                                :key="item.sysub_id" @click.native="switchSubject(index + 4)">
                                {{ item.sysub_name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                    返回
                </el-button>
            </div>
        </div>
        <div class="page-inner">
            <div class="search-form">
                <h5 class="bold total-num">当前总数： <span class="pf_bold">{{ listPage.total || 0 }}</span>道题</h5>
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-input v-model.trim="searchForm.stque_title" maxlength="100" placeholder="输入题干" clearable />
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.syque_typ_id" placeholder="选择题型" filterable clearable>
                            <el-option v-for="item in questionType" :key="item.syque_typ_id" :label="item.syque_typ_name"
                                :value="item.syque_typ_id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="searchForm.stque_difficulty" placeholder="选择难易程度" clearable>
                            <el-option label="简单" :value="10" />
                            <el-option label="一般" :value="20" />
                            <el-option label="困难" :value="30" />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="searchForm.stque_status < 40">
                        <el-select v-model="searchForm.stque_status" placeholder="选择掌握程度" clearable>
                            <el-option label="未掌握" :value="20" />
                            <el-option label="已掌握" :value="30" />
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="searchData">搜索</el-button>
            </div>
            <div class="wrong-questions-wrapper">
                <div class="wrong-questions-box" :class="{ 'no-data': $isEmpty(listData) }" v-loading="dataLoad">
                    <ul class="wrong-questions-list" v-if="!$isEmpty(listData)">
                        <li class="question" :class="{ compose: isCompose(que.stque_ismakeup) }"
                            v-for="(que, que_index) in listData" :key="que.stque_id">
                            <span class="question--num pf_bold">
                                {{ queNum(que_index + 1) }}.
                            </span>
                            <span class="question--type">{{ que.syque_typ_name }}</span>
                            <div class="question--icon">
                                <el-button type="primary" plain icon="el-icon-search" size="mini" v-if="que.syque_typ_id==99" @click="showOfficeItem(que)">查看错题</el-button>
                            </div>
                            <div class="question--data">
                                <!-- 组合题 -->
                                <template v-if="isCompose(que.stque_ismakeup)">
                                    <h5 class="bold richblock" v-html="que.stque_title"></h5>
                                    <div class="imgs-group" v-if="que.stque_image">
                                        <img class="selectimg" v-if="formatfile(que.stque_image)" :src="formatfile(que.stque_image)" alt="">
                                        <!-- <el-image fit="cover" :src="formatfile(que.stque_image)" :preview-src-list="[formatfile(que.stque_image)]" /> -->
                                    </div>
                                    <ol class="subtopic">
                                        <li class="subtopic-item" v-for="(sque, sque_index) in que.children_question"
                                            :key="sque.stque_id">
                                            <p class="subtopic--title">
                                                （{{ sque_index + 1 }}）【{{ sque.syque_typ_name }}】<span class="richinline" v-html="sque.stque_title"></span>
                                            </p>
                                            <div class="imgs-group" v-if="sque.stque_image">
                                                <img class="selectimg" v-if="formatfile(sque.stque_image)" :src="formatfile(sque.stque_image)" alt="">
                                                <!-- <el-image fit="cover" :src="formatfile(sque.stque_stuimages)" :preview-src-list="[formatfile(sque.stque_stuimages)]" /> -->
                                            </div>
                                            <dl class="subtopic--options" v-if="isChoice(sque.syque_typ_id)">
                                                <dt class="option" v-for="opt in sque.question_child_item"
                                                    :key="opt.stque_ite_id">
                                                    {{ opt.stque_ite_code }}. {{ opt.stque_ite_title }}
                                                    <div class="imgs-group" v-if="opt.stque_ite_image">
                                                        <img class="selectimg" v-if="formatfile(opt.stque_ite_image)" :src="formatfile(opt.stque_ite_image)" alt="">
                                                        <!-- <el-image fit="cover" :src="formatfile(opt.stque_ite_image)" :preview-src-list="[formatfile(opt.stque_ite_image)]" /> -->
                                                    </div>
                                                </dt>
                                            </dl>
                                            <div class="question-condition">
                                                <p>答错次数<b class="pf_bold danger">{{ sque.stque_errornum || 0 }}</b>次</p>
                                                <p>答对次数<b class="pf_bold success">{{ sque.stque_correctnum || 0 }}</b>次</p>
                                            </div>
                                        </li>
                                    </ol>
                                </template>
                                <!-- 其他题型 -->
                                <template v-if="!isCompose(que.stque_ismakeup)">
                                    <h5 class="bold richblock" v-html="que.stque_title"></h5>
                                    <div class="imgs-group" v-if="que.stque_image">
                                        <img class="selectimg" v-if="formatfile(que.stque_image)" :src="formatfile(que.stque_image)" alt="">
                                        <!-- <el-image fit="cover" :src="formatfile(que.stque_image)" :preview-src-list="[formatfile(que.stque_image)]" /> -->
                                    </div>
                                    <ul class="options" v-if="isChoice(que.syque_typ_id)">
                                        <li v-for="opt in que.children_question" :key="opt.stque_ite_id">
                                            {{ opt.stque_ite_code }}. <span class="richinline" v-html="opt.stque_ite_title"></span>
                                            <div class="imgs-group" v-if="opt.stque_ite_image">
                                                <img class="selectimg" v-if="formatfile(opt.stque_ite_image)" :src="formatfile(opt.stque_ite_image)" alt="">
                                                <!-- <el-image fit="cover" :src="formatfile(opt.stque_ite_image)" :preview-src-list="[formatfile(opt.stque_ite_image)]" /> -->
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                            <!-- 其他题型 -- 底部 -->
                            <div class="question-condition" v-if="!isCompose(que.stque_ismakeup)">
                                <p>答错次数<b class="pf_bold danger">{{ que.stque_errornum || 0 }}</b>次</p>
                                <p>答对次数<b class="pf_bold success">{{ que.stque_correctnum || 0 }}</b>次</p>
                            </div>
                        </li>
                    </ul>
                    <div class="no-data--empty" v-if="$isEmpty(listData) && !dataLoad">
                        <img src="@assets/images/no-data3.png" alt="">
                        <p>暂无错题数据哦~</p>
                    </div>
                </div>
                <!-- 分页控件 -->
                <div class="custom-foot">
                    <customPagination :current='listPage.pageIndex' :total="listPage.total" @pageChange="flippingPage" />
                </div>
            </div>
            <el-dialog title="错题详情" :visible.sync="showOffice" width="944px">
               <div class="office">
                    <vueOffice v-model="office_paper_url" v-if="showOffice" type="pdf"></vueOffice>
                </div>
            </el-dialog>
        </div>
    </section>
</template>

<script>
import { $wrongQues, $students } from "@api/analysis"
import { $getTeachSubject } from "@api/teaching"
import { mapState } from "vuex"
import { formatFile } from "@utils"
import { PAGE_SIZE } from "@config";
import customPagination from "@comp/customPagination"
import vueOffice from "@comp/vueOffice"
export default {
    name: 'analysis_wrongQuestions',
    components: { customPagination,vueOffice },
    computed: {
        ...mapState('common', ['questionType']),
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
        /** 判断是否是选择题 */
        isChoice() {
            const choiceTypeIds = [1, 2, 5];
            return function (val) {
                return choiceTypeIds.indexOf(val) != -1;
            };
        },
        /** 判断是否是组合题 */
        isCompose() {
            return function (val) {
                return val == 10;
            };
        },
        queNum() {
            return function (val) {
                let antecedent = (this.listPage.pageIndex - 1) * (this.listPage.size || PAGE_SIZE);
                let num = val + antecedent;
                num = num > 9 && num || `0${num}`
                return num;
            };
        },
    },
    data() {
        return {
            searchForm: {}, // 统计数据筛选条件
            teachSubject: [], // 教师所授班级科目
            teachStudents: [], // 教师所授班级学生
            currentStudent: null,
            listData: [], // 课件列表数据
            /* 表格分页参数 */
            listPage: {
                pageIndex: 1,
                total: 0
            },
            dataLoad: false,
            //显示pdf
            showOffice:false,
            office_paper_url:"",
        }
    },
    created() {
        let { id } = this.$route.params;
        this.searchForm.sccla_id = id.split(',')[0];
        if (id.split(',')[1] != 0) this.searchForm.stuser_id = id.split(',')[1];
        if (id.split(',')[2] != 0) this.searchForm.sysub_id = Number(id.split(',')[2]);
        if (this.$route.name == 'ANALYSIS_DONEWRONGQUESTIONS') this.searchForm.stque_status = 40
        this.getSubject();
        this.getStudents();
        this.getData();
    },
    methods: {
        /** 获取教师所授班级科目 */
        async getSubject() {
            let { data } = await $getTeachSubject(this.searchForm.sccla_id);
            this.teachSubject = data;
        },
        /** 获取班级学生 */
        async getStudents() {
            let { data } = await $students(this.searchForm.sccla_id);
            data.map(item => {
                if (item.stuser_id == this.searchForm.stuser_id) {
                    this.currentStudent = item;
                }
            })
            this.teachStudents = data;
        },
        /** 获取错题列表 */
        async getData() {
            let params = {
                ...this.searchForm,
                pageindex: this.listPage.pageIndex
            }
            this.dataLoad = true;
            let { data: res } = await $wrongQues(params);
            this.dataLoad = false;
            this.listPage.total = res.allcount;
            this.listData = res.data;
            this.$forceUpdate();
        },
        /** 切换学生 */
        chooseStudent(data) {
            if (data) {
                this.searchForm.stuser_id = data.stuser_id
            } else {
                delete this.searchForm.stuser_id
            }
            this.currentStudent = data;
            this.searchData();
        },
        /** 切换科目 */
        switchSubject(index) {
            if (index || index == 0) {
                this.searchForm.sysub_id = this.teachSubject[index].sysub_id;
                if (index > 3) {
                    let firData = this.teachSubject[index];
                    let replaceData = this.teachSubject[3];
                    this.teachSubject[3] = { ...firData };
                    this.teachSubject[index] = { ...replaceData };
                }
            } else {
                delete this.searchForm.sysub_id;
            }
            this.searchData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.listPage.pageIndex = val;
            this.getData();
        },
        /** 筛选数据 */
        searchData() {
            this.listData = [];
            this.listPage.pageIndex = 1;
            this.getData();
        },
        //查看pdf
        showOfficeItem(item){
            this.showOffice=true
            this.office_paper_url=item.stque_att_paper_url
        }
    }
}
</script>